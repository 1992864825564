import { useState, useEffect } from 'react';
import { InputDinamycPlaceholder } from '@lk-gtcom/ecomlab-components';
import {
  KonstantinAccountInSettingsHttps,
  KonstantinAccountInSettingsHttp,
} from '../../fetchUrls';
import { ModalSuccessful } from '../Modal/ModalSuccessful/ModalSuccessful';
import Cookies from 'universal-cookie';
import './AccountSafety.scss';

const AccountSafety = ({
  changePassword,
  setChangePassword,
  setShowModalPasswordRecovery,
  setIsFormValid,
}) => {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  const [errPassword, setErrPassword] = useState(false);
  const [errNewPassword, setErrNewPassword] = useState(false);
  const [errPasswordRepeat, setErrPasswordRepeat] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const auth = new Cookies().get('auth');
  const device_info = window.navigator.userAgent;

  const regexPassword =
    /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}/;

  const validateForm = (pass, newPass, repeatPass) => {
    const allFieldsValid =
      regexPassword.test(pass) &&
      regexPassword.test(newPass) &&
      regexPassword.test(repeatPass) &&
      newPass === repeatPass;
    setIsFormValid(allFieldsValid);
  };

  const handlerPassword = (e) => {
    const value = e;
    setPassword(value);
    validateForm(value, newPassword, passwordRepeat);
  };

  const handlerNewPassword = (e) => {
    const value = e;
    setNewPassword(value);
    validateForm(password, value, passwordRepeat);
  };

  const handlerPasswordRepeat = (e) => {
    const value = e;
    setPasswordRepeat(value);
    validateForm(password, newPassword, value);
  };

  const handleBlurPassword = () => {
    setErrPassword(password !== '' && !regexPassword.test(password));
  };

  const handleBlurNewPassword = () => {
    setErrNewPassword(newPassword !== '' && !regexPassword.test(newPassword));
  };

  const handleBlurPasswordRepeat = () => {
    setErrPasswordRepeat(
      passwordRepeat !== '' &&
        (!regexPassword.test(passwordRepeat) || passwordRepeat !== newPassword)
    );
  };

  const fetchChangePassword = () => {
    const url = window.location.hostname.match('localhost')
      ? `${KonstantinAccountInSettingsHttp}/gtcom/web/change-password`
      : `${KonstantinAccountInSettingsHttps}/gtcom/web/change-password`;

    const body = JSON.stringify({
      old_password: password,
      new_password: newPassword,
      device_info: device_info,
    });

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth}`,
    };

    fetch(url, { body, method: 'POST', headers })
      .then((res) =>
        res.json().then((json) => {
          const {
            access_token,
            client_id,
            login,
            not_one_account,
            refresh_token,
          } = json;
          const cookies = new Cookies();
          let d = new Date();
          let d2 = new Date();
          d.setTime(d.getTime() + 1000 * 60 * 60 * 24);
          d2.setTime(d.getTime() + 1000 * 60 * 60 * 24 * 7);

          if (login) {
            localStorage.setItem('email', login);
          }
          if (client_id) {
            localStorage.setItem('client_id', client_id);
          }
          if (not_one_account !== 'undefined') {
            localStorage.setItem('not_one_account', not_one_account);
          }
          if (access_token) {
            cookies.set('auth', access_token, { expires: d });
          }
          if (refresh_token) {
            cookies.set('refresh', refresh_token, { expires: d2 });
          }
          setIsModal(true);
        })
      )
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (changePassword) {
      const allFieldsValid =
        regexPassword.test(password) &&
        regexPassword.test(newPassword) &&
        regexPassword.test(passwordRepeat) &&
        newPassword === passwordRepeat;

      if (allFieldsValid) {
        fetchChangePassword();
      } else {
        if (!regexPassword.test(password)) setErrPassword(true);
        if (!regexPassword.test(newPassword)) setErrNewPassword(true);
        if (
          !regexPassword.test(passwordRepeat) ||
          newPassword !== passwordRepeat
        ) {
          setErrPasswordRepeat(true);
        }
      }
      setChangePassword(false);
    }
  }, [changePassword]);

  return (
    <>
      {isModal && (
        <ModalSuccessful
          title="Password successfully changed"
          text="You can always change your password in settings"
          setIsModal={(e) => setIsModal(e)}
        />
      )}
      <div className="account-safety">
        <h2 className="title">Change Password</h2>

        <div style={{ marginTop: '28px' }}>
          <InputDinamycPlaceholder
            onChange={(e) => handlerPassword(e)}
            onBlur={handleBlurPassword}
            type="password"
            err={errPassword}
            textErr="Password must contain at least 8 characters, including numbers and uppercase letters"
            classname="autorization__input-field"
            placeholder="Old Password"
            autoComplete={false}
            style={{ marginTop: '28px' }}
          />
          <a
            href="#"
            className="link__blue"
            onClick={(e) => {
              e.preventDefault();
              setShowModalPasswordRecovery(true);
            }}
          >
            Forgot password?
          </a>
        </div>

        <div style={{ marginTop: '11px' }}>
          <InputDinamycPlaceholder
            onChange={(e) => handlerNewPassword(e)}
            onBlur={handleBlurNewPassword}
            type="password"
            err={errNewPassword}
            textErr="Password must contain at least 8 characters, including numbers and uppercase letters"
            placeholder="New Password"
            autoComplete={false}
          />
        </div>

        <div style={{ marginTop: '15px' }}>
          <InputDinamycPlaceholder
            onChange={(e) => handlerPasswordRepeat(e)}
            onBlur={handleBlurPasswordRepeat}
            type="password"
            err={errPasswordRepeat}
            textErr="Password must contain at least 8 characters, including numbers and uppercase letters, or passwords do not match"
            placeholder="Confirm New Password"
            autoComplete={false}
          />
        </div>
      </div>
    </>
  );
};

export { AccountSafety };
