import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import {
  ArtemBilling2HttpsUpBalance,
  ArtemBilling2HttpUpBalance,
  OlegPaymentHttp,
  OlegPaymentHttps,
} from '../../fetchUrls';
import { icon_add_balance } from '../../common/svg_img';
import initTranslations from '../../i18n';
import './AddBalanceModal.scss';
import Cookies from 'universal-cookie';

const namespaces = ['AddBalanceModal'];

const AddBalanceModal = ({ setIsModalActive }) => {
  const [i18n, seti18n, ready] = useState({}, { useSuspense: false });

  const translate = i18n?.t;
  const t = (key) => {
    if (typeof translate === 'function') {
      return translate(key);
    }
  };

  const initTranslation = async () => {
    const { i18n } = await initTranslations('en', namespaces);
    seti18n(i18n);
  };

  useEffect(() => {
    initTranslation();
  }, []);

  const navigate = useNavigate();
  const [balanceValue, setBalanceValue] = useState('');
  const [errorVisability, setErrorVisability] = useState(false);

  const balanceIncreaseRegister = () => {
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${ArtemBilling2HttpsUpBalance}/api/v1/register-order?amount=${
        balanceValue * 100
      }`;
    } else {
      url = `${ArtemBilling2HttpUpBalance}/api/v1/register-order?amount=${
        balanceValue * 100
      }`;
    }

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'client-id': `${localStorage.getItem('client_id')}`,
      Authorization: `Bearer ${new Cookies().get('auth')}`,
    };

    fetch(url, { method: 'POST', headers })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Request failed!');
        }
        return res.json();
      })
      .then((json) => {
        console.log(json, 'json');
        const { order_id, form_url, status } = json;

        if (status === 'ok' && order_id) {
          localStorage.setItem('tariff_id', order_id);
          window.location.assign(form_url);
        }
        if (!status == 'ок') {
          setErrorVisability(true);
        }
      })
      .catch((err) => {
        console.error('Error:', err);
        setErrorVisability(true);
      });
  };

  return (
    <div
      className="blackout"
      onClick={(e) => {
        let el = e.target;
        if (el.classList.contains('blackout')) {
          setIsModalActive(false);
        }
      }}
    >
      <div className="add-balance-modal">
        <button
          className="close"
          onClick={(e) => setIsModalActive(false)}
        ></button>

        <img src={icon_add_balance} />

        <h2 className="title" style={{ padding: '0' }}>
          {t('AddBalanceModal:Top_up_balance')}
        </h2>
        <span
          style={{
            color: 'red',
            fontSize: '14px',
            margin: 0,
            visibility: errorVisability ? 'visible' : 'hidden',
          }}
        >
          {t('AddBalanceModal:err')}
        </span>
        <div className="input-and-text-box" style={{ marginTop: '.5em' }}>
          <span className="text">{t('AddBalanceModal:Top_up_amount')}:</span>
          <div className="input-box">
            <input
              className="add-balance-modal__input-field"
              type="number"
              placeholder="0.00"
              value={balanceValue}
              onChange={(e) => setBalanceValue(e.target.value)}
            ></input>
          </div>
        </div>

        <button
          className="btn__green"
          onClick={(e) => balanceIncreaseRegister(e)}
        >
          {t('AddBalanceModal:Top_up_balance')}
        </button>
      </div>
    </div>
  );
};

export { AddBalanceModal };
