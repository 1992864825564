import { useState } from 'react'
import { LineChart } from '../../charts/LineChart/LineChart'
import { TableBox, DropContainer } from "@lk-gtcom/ecomlab-components"
import "./ProductCardExtended_OrdersAndSales.scss"

const ProductCardExtended_OrdersAndSales = ({
    tableData,
    chartData,
    tableCallback,
    metricsList,
    selectedMetric,
    setSelectedMetric,
    paginatorRef
}) => {

    const [showDropBox, setShowDropBox] = useState(true)

    return (
        <section className='orders-and-sales-inside-content'>
            <DropContainer
                lang='en'
                showDropBox={showDropBox}
                setShowDropBox={(e) => setShowDropBox(e)}
            >
                <LineChart
                    {...chartData}
                    lang='en'
                metricsList={metricsList}
                selectedMetric={selectedMetric}
                setSelectedMetric={setSelectedMetric}
                />
            </DropContainer>

            <TableBox
                {...tableData}
                sortingFunc={tableCallback}
                ref={paginatorRef}
                paginator={true}
                fetchCallback={tableCallback}
            />
        </section>
    )
}

export { ProductCardExtended_OrdersAndSales }