// Vladimir auth/signup/reset-pass/

export const VladimirHttps = 'https://apps0.ecomru.ru:4432';
export const VladimirHttp = 'https://apps0.ecomru.ru:4432'; // 8082

export const VladimirModalHttps = 'https://apps0.ecomru.ru:4435';
export const VladimirModalHttp = 'https://apps0.ecomru.ru:4435'; // 8085

export const VladimirAccountsHttps = 'http://apps0.ecomru.ru:8085';
export const VladimirAccountsHttp = 'http://apps0.ecomru.ru:8085';

//

export const VladimirEmployeesHttps = 'https://apps2.ecomru.ru:4436';
export const VladimirEmployeesHttp = 'https://apps2.ecomru.ru:4436';

// Oleg main/

export const OlegRulesHttps = 'https://apps1.ecomru.ru:4100';
export const OlegRulesHttp = 'https://apps1.ecomru.ru:4100'; //8200

export const OlegHttps = 'https://apps1.ecomru.ru:4450';
export const OlegHttp = 'https://apps1.ecomru.ru:4450'; // 8090

export const Oleg2Https = 'https://apps2.ecomru.ru:4450';
export const Oleg2Http = 'https://apps2.ecomru.ru:4450'; // 8090

export const OlegOrdersHttps = 'https://apps2.ecomru.ru:4443';
export const OlegOrdersHttp = 'https://apps2.ecomru.ru:4443';

export const OlegPaymentHttps = 'https://apps0.ecomru.ru:4444';
export const OlegPaymentHttp = 'https://apps0.ecomru.ru:4444'; //8094

export const OlegActionsAddHttps = 'https://apps2.ecomru.ru:4100';
export const OlegActionsAddHttp = 'https://apps2.ecomru.ru:4100';

// Konstantin main/

export const KonstantinHttps = 'https://apps1.ecomru.ru:4434';
export const KonstantinHttp = 'https://apps1.ecomru.ru:4434'; //8083

export const KonstantinPriceUpdateHttps = 'https://apps2.ecomru.ru:4451';
export const KonstantinPriceUpdateHttp = 'http://apps2.ecomru.ru:4451'; //8083

export const KonstantionAuthHttp = 'https://apps0.ecomru.ru:4430';
export const KonstantionAuthHttps = 'https://apps0.ecomru.ru:4430';

export const KonstantionAuthHttp2 = 'https://gtcom.io:4430';
export const KonstantionAuthHttps2 = 'https://gtcom.io:4430';

export const KonstantionGtcomAuthHttps = 'https://auth.gtcom.io:4431';
export const KonstantionGtcomAuthHttp = 'https://auth.gtcom.io:4431';

export const KonstantinMasterHttps = 'https://apps0.ecomru.ru:4449';
export const KonstantinMasterHttp = 'https://apps0.ecomru.ru:4449';

export const ArtemHttps = 'https://apps1.ecomru.ru:4437';
export const ArtemHttp = 'https://apps1.ecomru.ru:4437'; // 8086

export const ArtemVideoHttps = `https://dl.ecomru.ru:4443`;
export const ArtemVideoHttp = `https://dl.ecomru.ru:4443`; //8081

export const ArtemPhotoHttps = 'https://dl.ecomru.ru:4444'; //http - 8083

export const ArtemTransferHttps = 'https://dl.ecomru.ru:5555';
export const ArtemTransferHttp = 'https://dl.ecomru.ru:5555'; // 5050

export const ArtemTransferTestHttps = 'https://dl.ecomru.ru:4440';
export const ArtemTransferTestHttp = 'https://dl.ecomru.ru:4440';

export const ArtemNotificationsHttps = 'https://dl.ecomru.ru:4445';
export const ArtemNotificationsHttp = 'https://dl.ecomru.ru:4445'; // 8084

export const ArtemBilling2HttpsUpBalance = 'https://apps2.ecomru.ru:4453';
export const ArtemBilling2HttpUpBalance = 'https://apps2.ecomru.ru:4453'; // 8090
export const ArtemBilling2Https = 'https://apps2.ecomru.ru:4454';
export const ArtemBilling2Http = 'https://apps2.ecomru.ru:4454'; // 8090
// export const ArtemBilling2Https = 'https://apps0.ecomru.ru:4448'
// export const ArtemBilling2Http = 'https://apps0.ecomru.ru:4448' // 8090

// Valeriya bundles

export const ValeriyaHttps = 'https://apps1.ecomru.ru:4435';
export const ValeriyaHttp = 'https://apps1.ecomru.ru:4435'; // 8084

export const ValeriyaArticlesHttps = 'https://apps2.ecomru.ru:4460';

// Andrey parselinks

export const AndreyHttps = 'https://appche3.ecomru.ru:4440';
export const AndreyHttp = 'https://appche3.ecomru.ru:4440'; // 8090

export const Andrey2Https = 'https://appche3.ecomru.ru:4440';
export const Andrey2Http = 'https://appche3.ecomru.ru:4440'; // 8090

export const AndreySkuHttps = 'https://appche3.ecomru.ru:4440';
export const AndreySkuHttp = 'https://appche3.ecomru.ru:4440';

// Sergey performance marketing

export const SergeyYandexHttps = 'https://apps1.ecomru.ru:4440';
export const SergeyOzonPerfHttps = 'https://apps1.ecomru.ru:4441';

// Valeriya task list

export const ValeriyaTaskListHttps = 'https://apps0.ecomru.ru:4460';
export const ValeriyaTaskListHttp = 'https://apps0.ecomru.ru:4460';

// Konstantin balance management

export const KonstantinPriceManagementHttps = 'https://apps1.ecomru.ru:4434';
export const KonstantinPriceManagementHttp = 'https://apps1.ecomru.ru:4434';

// Konstantin create product
export const KonstantinCreateProductHttps = 'https://apps1.ecomru.ru:4463';

// Konstantin account in settings

export const KonstantinAccountInSettingsHttps = 'https://apps0.ecomru.ru:4430';
export const KonstantinAccountInSettingsHttp = 'https://apps0.ecomru.ru:4430';

// Ravshan notification methods

export const RavshanHttps = 'https://apps0.ecomru.ru:4447';
export const RavshanHttp = 'https://apps0.ecomru.ru:4447';

// Oleg marketing

export const OlegMarketingHttps = 'https://apps1.ecomru.ru:4450';
export const OlegMarketingHttp = 'https://apps1.ecomru.ru:4450';

// Ravshan articles
export const RavshanArticlesHttps = 'https://apps0.ecomru.ru:4447';
