import { Link } from 'react-router-dom';
import logo from './img/Logotype.svg';
import "./Logo.scss"

const LogoBox = ({  }) => {

    return (
        <Link to="/top-categories"
            className='logo'>
            <img src={logo} />
        </Link>
    )
}

export { LogoBox }