import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { Tabs, ButtonBasic } from '@lk-gtcom/ecomlab-components';
import { AccountSettings } from '../AccountSettings/AccountSettings';
import { AccountNotifications } from '../AccountNotifications/AccountNotifications';
import { ModalPasswordRecovery } from '../Modal/ModalPasswordRecovery/ModalPasswordRecovery';
import { Mail } from '../PasswordRecoveryBox/Mail/Mail';
import { AccountSafety } from '../AccountSafety/AccountSafety';
import { ModalAddNotificationMethod } from '../Modal/ModalAddNotificationMethod/ModalAddNotificationMethod';
import { ModalAddTypeNotification } from '../Modal/ModalAddTypeNotification/ModalAddTypeNotification';
import './AccountManagement.scss';

const btn_tab = [
  {
    label: 'My details',
    value: 'my-details',
  },
  {
    label: 'Notifications',
    value: 'notifications',
  },
  {
    label: 'Security',
    value: 'safety',
  },
];

const AccountManagement = ({
  setIsModalActive,
  isModalActive,
  setConnectedModal,
  isModalFeedback,
}) => {
  const [pageValue, setPageValue] = useState(btn_tab[0].value);
  const path = useParams();
  const navigate = useNavigate();
  const activeTab = useParams()['*'];
  const [showModalPasswordRecovery, setShowModalPasswordRecovery] =
    useState(false);
  const [showModalChangedPassword, setShowModalChangedPassword] =
    useState(false);
  const [showModalSendPassword, setShowModalSendPassword] = useState(false);
  const [showAddMethodNotification, setShowAddMethodNotification] =
    useState(false);
  const [email, setEmail] = useState('');
  const [changeDataUser, setChangeDataUser] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [valid, setValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false); //change pass

  useEffect(() => {
    if (path['*'] === '') {
      navigate('my-details');
    }
  }, []);

  useEffect(() => {
    setPageValue(activeTab);
  }, [activeTab]);

  return (
    <>
      {showModalPasswordRecovery && (
        <ModalPasswordRecovery
          setIsModal={(e) => setShowModalPasswordRecovery(e)}
        />
      )}
      {showModalSendPassword && (
        <Mail
          text={`Password has been sent to your e-mail`}
          setIsModal={(e) => setShowModalSendPassword(e)}
        />
      )}
      {showModalChangedPassword && (
        <Mail
          title="Password changed successfully"
          text="You can always change your password in your personal account"
          setIsModal={(e) => setShowModalChangedPassword(e)}
        />
      )}
      {showAddMethodNotification && (
        <ModalAddNotificationMethod
          setIsModal={(e) => setShowAddMethodNotification(e)}
        />
      )}
      <div className="account-management">
        <h1 class="title_main">Account settings</h1>
        <Tabs tabs={btn_tab} pageValue={pageValue} />
        <div className="account-management__content">
          <Routes>
            <Route
              path="my-details"
              element={
                <AccountSettings
                  setValid={setValid}
                  changeDataUser={changeDataUser}
                  setChangeDataUser={(e) => setChangeDataUser(e)}
                />
              }
            />

            <Route
              path="notifications"
              element={
                <AccountNotifications
                  setConnectedModal={(e) => setConnectedModal(e)}
                  setIsModalActive={(e) => setIsModalActive(e)}
                  setIsModal={(e) => setShowAddMethodNotification(e)}
                  isModalActive={isModalActive}
                />
              }
            />
            <Route
              path="safety"
              element={
                <AccountSafety
                  changePassword={changePassword}
                  setChangePassword={(e) => setChangePassword(e)}
                  setShowModalPasswordRecovery={(e) =>
                    setShowModalPasswordRecovery(e)
                  }
                  setIsFormValid={setIsFormValid}
                />
              }
            />
          </Routes>
          <div className="group-btn">
            {pageValue != 'notifications' && (
              <ButtonBasic
                blue
                text="Save changes"
                width="200px"
                size="40px"
                onClick={(e) => {
                  if (pageValue === 'my-details') {
                    setChangeDataUser(true);
                  }
                  if (pageValue === 'safety') {
                    setChangePassword(true);
                  }
                }}
                disabled={pageValue === 'my-details' ? !valid : !isFormValid}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { AccountManagement };
