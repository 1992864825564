import { useState, useEffect } from 'react'
import { InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components"
import { KonstantionAuthHttps, KonstantionAuthHttp } from '../../../fetchUrls' 
import { Link, Routes, Route, useNavigate, useParams } from 'react-router-dom'
import Cookies from 'universal-cookie';
import './ModalPasswordRecovery.scss'
import { validationEmail } from '../../../common/validationFunc';
import { ModalErr } from '../../Modal/ModalErr/ModalErr';
import { ButtonBasic } from "@lk-gtcom/ecomlab-components";

const ModalPasswordRecovery = ({ setIsModal }) => {

    const path = useParams();
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [errEmail, setErrEmail] = useState(false)

    useEffect(() => {
        if (path['*'] === '') {
            navigate('email')
        }
    }, [])

    const [inValid, setInvalid] = useState(false)
    const [incorrectEmail, setIncorrectEmail] = useState(false)
    const [activeContent, setActiveContent] = useState(true)
    // const [disabledBtn, setdisabledBtn] = useState(false)
    const [isModalErr, setIsModalErr] = useState(false)
    const [isModalMail, setIsModalMail] = useState(true)
    const auth = new Cookies().get('auth')


    const handlerSetEmail = (e) => {
        setIncorrectEmail(false)
        setErrEmail(false)
        setEmail(e)
    }

    const checkInputField = () => {

        if (!validationEmail(email)) {
            setErrEmail(true)
        }

        if (validationEmail(email)) {
            handlerSubmit()
        }
    }
    
    const handlerSubmit = (e) => {

        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantionAuthHttps}/gtcom/web/forgot-password`
        } else {
            url = `${KonstantionAuthHttp}/gtcom/web/forgot-password`
        }

        const body = JSON.stringify({
            email
        })

        fetch(url,
            {
                body,
                method: 'POST',
                headers: { 'Content-type': 'application/json', 'Authorization': `Bearer ${auth}` }
            })
            .then(res => res.json())
            .then(async (json) => {
                setIsModalMail(true)
                setActiveContent(false)
            })
            .catch(err => {
                setIsModalErr(true)
                console.error(err)
            })
    }

    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
            }
        }}>
            <div className='password-recovery'>
                <button className='btn-close' onClick={(e) => setIsModal(false)}></button>
                <h2 className='title'
                    onClick={(e) => setIsModal(false)}
                >Restore password</h2>
                <div className='input-with-btn'>
                    <InputDinamycPlaceholder
                        onChange={e => handlerSetEmail(e)}
                        type="e-mail"
                        placeholder='E-mail'
                        err={errEmail}
                        textErr="Invalid email format"
                    />
                </div>
                <ButtonBasic
                    blue
                    style={{ marginTop: '4px' }}
                    width='100%'
                    size='40px'
                    text='Confirm'
                    onClick={e => {
                        checkInputField()
                    }}
                />
            </div>
        </div>
    )
}

export { ModalPasswordRecovery }