import headers from '../../common/headers';
import { useDebounce } from '../../hooks/useDebounce'
import { MultiLevelDropMenu } from '../MultiLevelDropMenu/MultiLevelDropMenu';
import { InputSearch, ButtonBasic } from "@lk-gtcom/ecomlab-components";
import { KonstantinCreateProductHttps } from '../../fetchUrls';
import { memo, useCallback, useEffect, useLayoutEffect, useId, useRef, useState } from 'react'
import '../Modal/Modal.scss'
import './FiltersBreadCrumbs.scss'

const FiltersBreadCrumbs = ({...props}) => {
    const {
        showMenu,
        setShowMenu,
        setSelectedBreadcrumbsValue,
        fetchCallback,
        callbackPromise,
        mapFunc,
        mustSelectLastLevel,
        selectedBreadcrumbsValue,
        setProductTemporary,
        createProduct = false,
        isSelectedBreadCrumsAfterButton = false,
        confirmLength = 1,
        fetchSearch
    } = props

    const [page, setPage] = useState(1)
    const [breadCrumbsList, setBreadCrumbsList] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedLevels, setSelectedLevels] = useState([])
    const [lastLevelSelected, setLastLevelSelected] = useState(false)
    const [search, setSearch] = useState("")
    const [searchData, setSearchData] = useState("")

    const fetchBreadCrumbs = useCallback(async (parentId, page = 1, index, scrollTopProp = -1, pageHeightProp = 0) => {
        setLoading(true)
        await fetchCallback(parentId === 'initial' ? '' : parentId, page)
            .then(async res => {
                if(res.ok){
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                let {categories_list, parent_id, total} = json
                const mappedData = categories_list.map(mapFunc)
                if(!parent_id) parent_id = 'initial'

                if (!mappedData.length) setLastLevelSelected(true)
                setBreadCrumbsList(prev => {
                    const copy = [...prev]
                    const key = copy[index]

                    if(key){
                        copy[index] = {
                            category_list: [...copy[index]?.category_list, ...mappedData],
                            parent_id,
                            total,
                            page,
                            scrollTopProp,
                            pageHeightProp
                        }
                    } else {
                        copy[index] = {
                            category_list: mappedData,
                            parent_id,
                            total,
                            page,
                            scrollTopProp,
                            pageHeightProp
                        }
                    }
                    return copy
                })
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, [])

    const fetchBreadCrumbsSearch = useCallback((value, abortController = new AbortController()) => {
        if(typeof fetchSearch === 'function'){
            fetchSearch(value, 1, abortController)
            .then(async res => {
                if(res.ok){
                    return res.json()
                } else {
                    const err = await res.json()
                    throw new Error(JSON.stringify(err))
                }
            })
            .then(json => {
                setSearchData(json?.categories_list ? json?.categories_list : json)
            })
            .catch(err => console.error(err))
        }
    }, [])

    const fetchSearchDebounce = useDebounce(fetchBreadCrumbsSearch, 1000)


    const fetchProductTemporary = ({selectedLevels}) => {
        if(createProduct){

            const lastBreadCrumbs = selectedLevels[selectedLevels.length - 1]
            const category_id = lastBreadCrumbs?.value?.id
            const category_name = lastBreadCrumbs?.label
            const category_name_list = selectedLevels.map(( {label, value: {id, hasChildren}} ) => ({ category_name: label, category_id: id, has_children: hasChildren }))

    
            const url = `${KonstantinCreateProductHttps}/master_products/temporary`
            const body = JSON.stringify({
                category_id,
                category_name,
                category_name_list
            })
    
            setLoading(true)
            fetch(url, { method: 'POST', body, headers})
            .then(async res => {
                if (res.ok) {
                    return res.json()
                }
                else {
                    const text = await res.json()
                    throw new Error(JSON.stringify(text))
                }
            })
            .then(data => {
                setProductTemporary(data)
                localStorage.setItem('productId', data?.product_id)
                if(callbackPromise){
                    callbackPromise(data)
                }
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
        }
    }


    useLayoutEffect(() => {
        if(Array.isArray(selectedBreadcrumbsValue)){
            setSelectedLevels([])
            setBreadCrumbsList([])
            let newArr
            if(selectedBreadcrumbsValue?.length > 0){
                setSelectedLevels(selectedBreadcrumbsValue)
                newArr = [...selectedBreadcrumbsValue]
            } else {
                newArr = [{parent_id: 'initial'}]
            }
            newArr?.map(async ({parent_id}, ind) => await fetchBreadCrumbs(parent_id, page, ind))
        }
    }, [selectedBreadcrumbsValue])


    const onLevelClick = useCallback((level, index) => {
        const {value: {id, hasChildren}, parent_id} = level

        const prevLevelInd = index > 0 ? index - 1 : 0

        if(level){
            setSelectedLevels(prev => {
                let res

                if((prev?.[prevLevelInd]?.value?.id === parent_id) || (parent_id === 'initial')){
                    let lastSelectedParent = [...prev].slice(0, index)
                    setBreadCrumbsList(bread => [...bread].slice(0, index + 1))
                    res = [...lastSelectedParent, level]
                } else {
                    res = [...prev, level]
                }
                return res
            })
        }
        if (hasChildren) fetchBreadCrumbs(id, page, index + 1)
    }, [])


    const BreadCrumbsLevel = memo(({...props}) => {
        const {
            index
        } = props

        let category_list, parent_id, total, pageProp, scrollTopProp, pageHeightProp, currentList
        currentList = breadCrumbsList?.[index]
        if(currentList){
            category_list = currentList?.category_list
            parent_id = currentList?.parent_id
            total = currentList?.total
            scrollTopProp = currentList?.scrollTopProp
            pageHeightProp = currentList?.pageHeightProp
            pageProp = currentList?.page
        }

        const [page, setPage] = useState(1)
        const [scrollTop, setScrollTop] = useState(scrollTopProp ? scrollTopProp : -1)
        const [pageHeight, setPageHeight] = useState(pageHeightProp ? pageHeightProp : 0)
        const [categories, setCategories] = useState([])
        const id = useId()
        const listRef = useRef()
        let isSearchLastPage = total <= category_list?.length

        const onScroll = () => {
            const { scrollTop, scrollHeight, clientHeight } = listRef.current
            const bottomHeight = scrollHeight - clientHeight
            if (bottomHeight) {
                setPageHeight(bottomHeight)
            }
            setScrollTop(scrollTop)
        }
    
        useEffect(() => {
            const timeout = setTimeout(() => {
                const currentPageHeight = pageHeight
                if (!isSearchLastPage && (Math.ceil(scrollTop) >= currentPageHeight) && (categories?.length > 0)) {
                    if(pageProp > 1){
                        setPage(pageProp + 1)
                    } else {
                        setPage(prev => prev + 1)
                    }
                    return
                }
            }, 500)
            return () => clearTimeout(timeout)
        }, [scrollTop, pageHeight, isSearchLastPage])


        useEffect(() => {
            if(page > 1){
                fetchBreadCrumbs(parent_id, page, index, scrollTop, pageHeight)
            }
        }, [page])


        useLayoutEffect(() => {
            if(category_list){
                setCategories(
                    [
                        ...category_list?.map(level => {
                            return (
                                <p
                                    className={level.value?.id === selectedLevels[index]?.value?.id ? 'selected' : (selectedLevels[index]?.value?.id ? 'no-selected' : '')}
                                    key={JSON.stringify(level) + Math.round(Math.random(0,1) * 1000 + Math.random(0,1) * 10000)}
                                    onClick={() => {
                                        if(!loading) {
                                            onLevelClick({...level, parent_id}, index)
                                        }
                                    }}
                                >
                                    {level.label}
                                </p>
                            )
                        })
                    ]
                )
            }
        }, [category_list])

        useLayoutEffect(() => {
            listRef.current.scrollTop = scrollTopProp
        }, [categories])

        return(
            <div className="filter-bread-crumbs-tab" key={`${index}-${parent_id}-${id}`} ref={listRef} onScroll={e => onScroll(e)}>
                {categories}
            </div>
        )

    })

    return (
        <>
            <div className='category-breadcrumbs'>
                    
                <ButtonBasic
                    whiteBlue
                    text='Select category'
                    onClick={(e) => setShowMenu(true)}
                    width='100%'
                    size='40px'
                />

                {selectedBreadcrumbsValue?.length > 0 &&
                    <div className={`category-breadcrumbs__content${isSelectedBreadCrumsAfterButton ? '_row' : ''}`}>
                        {selectedBreadcrumbsValue?.map(l => <p className='category-breadcrumbs__item'
                            onClick={() => setShowMenu(!showMenu)}
                            key={l?.value?.id}>{l?.label}</p>)}
                    </div>
                }

            </div>

            <div className={showMenu ? 'blackout' : ''}
                onClick={(e) => {
                    let el = e.target
                    if (el.classList.contains('blackout')) {
                        setShowMenu()
                    }
                }}
                style={{ display: showMenu ? 'flex' : 'none' }}>
                {
                    search?.length > 0 ?
                    searchData?.length > 0 && <div className='category-breadcrumbs__drop-content'>
                        <div className='category-breadcrumbs__scroll-content'>
                           <MultiLevelDropMenu
                                data={searchData}
                                setLevel={e => {
                                    setSelectedBreadcrumbsValue(e)
                                    setSearch('')
                                    setSearchData([])
                                }}
                            />
                        </div>
                    </div>
                    :
                    <div className="filter-bread-crumbs">
                        <div className={"tabs-container" + (loading ? " loading" : "")}>
                            {
                                breadCrumbsList?.map((__, ind) => <BreadCrumbsLevel key={ind} index={ind} />)
                            }
                        </div>

                        <ButtonBasic
                            violet
                            disabled={confirmLength ? selectedLevels.length < confirmLength : mustSelectLastLevel ? !lastLevelSelected : false}
                            text='Confirm'
                            size='40px'
                            width='242px'
                            onClick={() => {
                                setSelectedBreadcrumbsValue(selectedLevels)
                                setShowMenu()
                                fetchProductTemporary({ selectedLevels })
                            }}
                        />
                    </div>
                }

                <div className='category-breadcrumbs__input-container'
                    style={{ width: search?.length > 0 ? '100%' : '292px' }} >
                    <InputSearch
                        value={search}
                        onChange={value => {
                            const abortController = new AbortController()

                            fetchSearchDebounce(value, abortController)
                            setSearch(value)

                            return () => abortController.abort()
                        }}
                        placeholder='Search...'
                    />
                    <button className='close-btn' onClick={() => {
                        setShowMenu()
                    }} ></button>
                </div>
            </div>
        </>
    )
}

export { FiltersBreadCrumbs }